import React, { useEffect } from "react";
import { Link } from "@reach/router";
import Img from "gatsby-image"
import { navigate, StaticQuery, graphql } from "gatsby"
import Klauni from "../../../assets/img/funeralClowns.png";
import styled from "styled-components";
import DotaznikLayout from "../../../components/nabidka/dotaznik/DotaznikLayout";

const VeSpolupraci = styled.div`
  display: flex;
  align-items: baseline;
  margin-top: 20px;
  width: calc(100% - 50px);
  margin: 0 auto;
  margin-bottom: 15px;
  margin-top: -5px;
  position: absolute;
  top: 25px;
  left: 25px;
  z-index: 1;
  background: rgba(255, 255, 255, 0.9);
  padding: 2px 10px;
  width: fit-content;
  border-radius: 5px;


  p {
    font-family: Visuelt-Regular;
    font-size: 14px !important;
    text-align: left;
    line-height: 24px;
    color: #000 !important;
    margin: 0 !important;
    margin-right: 7px !important;
    width: fit-content !important;
  }

  img {
    position: relative;
    top: 1px;
    width: 27px;
    opacity: 1;
  }
`;


let Pohreb = ({userData, updateData}) => {
  let uprava = userData;

  
  function bezObradu() {
    uprava.typPohrbu = {
      "otazka": "Víte již jaký typ pohřbu byste chtěli objednat?",
      "odpoved": "bez_obradu",
    }

    updateData(uprava);
    navigate("/nabidka/dotaznik/konec/");
  }

  function obrad() {
    uprava.typPohrbu = {
      "otazka": "Víte již jaký typ pohřbu byste chtěli objednat?",
      "odpoved": "obrad",
    }
      
    updateData(uprava);
    navigate("/nabidka/dotaznik/konec/");
  }

  function netradicni() {
    navigate("/nabidka/dotaznik/netradicni/");
  }

  function nevim() {
    uprava.typPohrbu = {
      "otazka": "Víte již jaký typ pohřbu byste chtěli objednat?",
      "odpoved": "nevim"
    }

    updateData(uprava);
    navigate("/nabidka/dotaznik/konec/");
  }

  if (userData?.region) {
    return (
      <StaticQuery
        query={graphql`
          query {
            bezObradu: file(relativePath: { eq: "bezObradu.jpg" }) {
              childImageSharp {
                fixed(width: 320, quality: 100) {
                  ...GatsbyImageSharpFixed_noBase64
                }
              }
            }

            sObradem: file(relativePath: { eq: "rakev.jpg" }) {
              childImageSharp {
                fixed(width: 320, quality: 100) {
                  ...GatsbyImageSharpFixed_noBase64
                }
              }
            }

            netradicni: file(relativePath: { eq: "priroda.jpg" }) {
              childImageSharp {
                fixed(width: 320, quality: 100) {
                  ...GatsbyImageSharpFixed_noBase64
                }
              }
            }
          }
        `}
        render={data => (
          <DotaznikLayout phase={2} progress={50}>
            <h1>Víte, jaký typ pohřbu chcete objednat?</h1>
            <p>Nemusíte se bát, všechny detaily s vámi ještě probereme po telefonu.</p>

            <div className="vyberPohrbu">          
              <button className="obrazkovyButton" onClick={() => bezObradu()}>
                <Img className="obrazek" alt="Kremace bez obřadu" fixed={data.bezObradu.childImageSharp.fixed} />
                <h2>Kremace bez obřadu</h2>
                <p>Kremace, která probíhá bez obřadu nebo účasti pozůstalých. Samotná forma rozloučení je potom jen na vás.</p>
                <span className="cena">od {userData.region.odpoved.cenaBezObradu.toLocaleString("fr-FR")} Kč</span>
              </button>

              {userData.region.odpoved.cenaObrad !== 0 &&
                <button className="obrazkovyButton" onClick={() => obrad()}>
                  <Img className="obrazek" alt="Pohřeb s obřadem" fixed={data.sObradem.childImageSharp.fixed} />
                  <h2>Pohřeb s obřadem</h2>
                  <p>Důstojné rozloučení v obřadní síni, které vám dá možnost říci vašemu blízkému poslední sbohem.</p>
                  <span className="cena">od {userData.region.odpoved.cenaObrad.toLocaleString("fr-FR")} Kč</span>
                </button>
              }

              {userData.region.odpoved.nabizimeNetradicni &&
                <button className="obrazkovyButton" onClick={() => netradicni()}>
                  <VeSpolupraci>
                    <p>Pohřební průvodci</p>
                    <img src={Klauni} alt="Pohřební průvodci logo" />
                  </VeSpolupraci>
                  <Img className="obrazek" alt="Netradiční pohřeb" fixed={data.netradicni.childImageSharp.fixed} />
                  <h2>Netradiční pohřeb</h2>
                  <p>Lampiony, rozptyl popela do řeky nebo zasazení stromu? Naplánujte jedinečný obřad přesně podle sebe.</p>
                  <span className="cena">od 27 990 Kč</span>
                </button>
              }

              <button onClick={() => nevim()}>Ještě nevím</button>
            </div>

            <Link to="/nabidka/dotaznik/region/" className="zpet">← Zpět</Link>
          </DotaznikLayout>
        )}
      />
    );
  }

  else {
    return(<h1>Stala se chyba</h1>);
  }
};


export default Pohreb;